import loadable from '@loadable/component';
import { Route, Routes } from 'react-router-dom';

import BackdropLoading from '../components/Shared/BackdropLoading/backdropLoading.component';

import { ProtectedRoute } from './protectedRoute';

const ManagerMainPage = loadable(() => import('../pages/Manager/index'), {
  fallback: <BackdropLoading />,
});

const ManageListProject = loadable(() => import('../pages/Manager/manageProject'), {
  fallback: <BackdropLoading />,
});

const ManageNotification = loadable(() => import('../pages/Manager/manageNotification'), {
  fallback: <BackdropLoading />,
});

const ManageUserGuide = loadable(() => import('../pages/Manager/manageUserGuide'), {
  fallback: <BackdropLoading />,
});

const AccountPage = loadable(() => import('../pages/Manager/manageAccountDetail'), {
  fallback: <BackdropLoading />,
});

const Setting = loadable(() => import('../pages/Manager/scheduleSetting'), {
  fallback: <BackdropLoading />,
});

const ChangeLog = loadable(() => import('../pages/Manager/manageChangeLog'), {
  fallback: <BackdropLoading />,
});

const CNProject = loadable(() => import('../pages/Manager/manageProjectDetail'), {
  fallback: <BackdropLoading />,
});

const ManagerRoute = () => (
  <Routes>
    <Route
      index
      element={
        <ProtectedRoute userRole="manager">
          <ManagerMainPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="project"
      element={
        <ProtectedRoute userRole="manager">
          <ManageListProject />
        </ProtectedRoute>
      }
    />
    <Route
      path="project/:id"
      element={
        <ProtectedRoute userRole="manager">
          <CNProject />
        </ProtectedRoute>
      }
    />
    <Route
      path="notifications"
      element={
        <ProtectedRoute userRole="manager">
          <ManageNotification />
        </ProtectedRoute>
      }
    />
    <Route
      path="user-guide"
      element={
        <ProtectedRoute userRole="manager">
          <ManageUserGuide />
        </ProtectedRoute>
      }
    />
    <Route
      path="account"
      element={
        <ProtectedRoute userRole="manager">
          <AccountPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="settings"
      element={
        <ProtectedRoute userRole="manager">
          <Setting />
        </ProtectedRoute>
      }
    />
    <Route
      path="history"
      element={
        <ProtectedRoute userRole="manager">
          <ChangeLog />
        </ProtectedRoute>
      }
    />
  </Routes>
);

export default ManagerRoute;
