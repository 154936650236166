import loadable from '@loadable/component';
import { Route, Routes } from 'react-router-dom';

import BackdropLoading from '../components/Shared/BackdropLoading/backdropLoading.component';

import { ProtectedRoute } from './protectedRoute';

const AdminManageAccount = loadable(() => import('../pages/Admin/adminManageAccount'), {
  fallback: <BackdropLoading />,
});

const AdminManageHistory = loadable(() => import('../pages/Admin/adminManageHistory'), {
  fallback: <BackdropLoading />,
});

const AdminManageOrganization = loadable(() => import('../pages/Admin/adminManageOrganization'), {
  fallback: <BackdropLoading />,
});

const AdminRoute = () => (
  <Routes>
    <Route
      index
      element={
        <ProtectedRoute userRole="admin">
          <AdminManageAccount />
        </ProtectedRoute>
      }
    />
    <Route
      path="manage-account"
      element={
        <ProtectedRoute userRole="admin">
          <AdminManageAccount />
        </ProtectedRoute>
      }
    />
    <Route
      path="manage-organization"
      element={
        <ProtectedRoute userRole="admin">
          <AdminManageOrganization />
        </ProtectedRoute>
      }
    />
    <Route
      path="manage-history"
      element={
        <ProtectedRoute userRole="admin">
          <AdminManageHistory />
        </ProtectedRoute>
      }
    />
  </Routes>
);

export default AdminRoute;
