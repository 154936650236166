import { combineReducers } from '@reduxjs/toolkit';

import authReducer from './authSlice';
import loginPageReducer from './loginSlice';
import projectFormReducer from './ProjectFormSlice';
// import projectMemberReducer from './ProjectForm/ProjectMemberSlice';
// import projectResultReducer from './ProjectForm/ProjectResultSlice';
import uiControlReducer from './uiControlSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  loginPage: loginPageReducer,
  uiControl: uiControlReducer,
  projectForm: projectFormReducer,
  // projectMember: projectMemberReducer,
  // projectResult: projectResultReducer,
});

export default rootReducer;
