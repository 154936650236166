import { colors } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';

import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
  palette: {
    background: {
      default: '#F4F6F8',
      paper: colors.common.white,
    },
    primary: {
      contrastText: '#ffffff',
      main: '#293C7C',
    },
    text: {
      primary: '#172b4d',
      secondary: '#6b778c',
    },
  },
  overrides: {
    MUIDataTableHeadCell: {
      fixedHeader: {
        backgroundColor: '#89d0f0',
      },
      data: {
        fontSize: 'medium',
      },
    },
    MUIDataTableToolbar: {
      titleText: {
        fontSize: 'medium',
      },
    },
    MUIDataTableSelectCell: {
      headerCell: {
        backgroundColor: '#89d0f0',
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          color: green[600],
        },
      },
    },
    MuiCard: {
      root: {
        boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
      },
    },
  },
  shadows,
  typography,
});

export default theme;
