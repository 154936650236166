import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: null,
  role: null,
  userInfo: {},
  expireTime: 0,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setExpireTime: (state, action) => {
      state.expireTime = action.payload;
    },
    resetAuthInfo: () => initialState,
  },
});

export const { setToken, setRole, setUserInfo, setExpireTime, resetAuthInfo } = authSlice.actions;

export default authSlice.reducer;
