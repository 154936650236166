import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import io from 'socket.io-client';

import { SOCKET_NOTIFICATION } from '../../../configs/constantConfigs';

export const SocketNotification = () => {
  const { token } = useSelector((state) => state.auth);

  const handleSocketEvt = (e) => {
    toast.success(e);
  };

  // socket listener for notification
  useEffect(() => {
    let socketInstance;
    if (token) {
      socketInstance = io.connect(SOCKET_NOTIFICATION, {
        path: '/socket/notification/socket.io/',
        query: {
          Authorization: token,
        },
      });

      socketInstance.on('Notification', handleSocketEvt);
    }

    return () => {
      if (token) {
        socketInstance.off('Notification');
      }
    };
  }, [token]);

  return null;
};
