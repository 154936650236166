/* eslint-disable react/jsx-props-no-spreading */
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

export const ProtectedRoute = ({ children, userRole }) => {
  const { token, role } = useSelector((state) => state.auth);

  const location = useLocation();

  if (token && role && userRole === role) {
    return children;
  }

  return (
    <Navigate to={`/login?return-url=${location.pathname}`} state={{ from: location }} replace />
  );
};
