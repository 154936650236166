export const API_URL_AUTH = process.env.REACT_APP_API_URL_AUTH;
export const API_PROJECT_COMMON_INFO_MANAGER =
  process.env.REACT_APP_API_URL_PROJECT_COMMON_INFO_MANAGER;

export const API_NOTIFICATION = process.env.REACT_APP_API_URL_NOTIFICATION;
export const SOCKET_NOTIFICATION = process.env.REACT_APP_API_URL_NOTIFICATION_SOCKET;

export const API_MANAGE_FILE = process.env.REACT_APP_API_URL_FILE;
export const API_CATEGORY_DATA_MANAGER = process.env.REACT_APP_API_CATEGORY_DATA_MANAGER;

export const API_MAIL = process.env.REACT_APP_API_EMAIL;
