import loadable from '@loadable/component';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import BackdropLoading from '../components/Shared/BackdropLoading/backdropLoading.component';

import AdminRoute from './adminRoute';
import ManagerRoute from './managerRoute';
import { ProtectedRoute } from './protectedRoute';
import UserRoute from './userRoute';

const Login = loadable(() => import('../pages/Auth/Login'), { fallback: <BackdropLoading /> });

const AppRoute = () => (
  <Router>
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="/" element={<Navigate replace to="/login" />} />
      <Route
        path="admin/*"
        element={
          <ProtectedRoute userRole="admin">
            <AdminRoute />
          </ProtectedRoute>
        }
      />
      <Route
        path="manager/*"
        element={
          <ProtectedRoute userRole="manager">
            <ManagerRoute />
          </ProtectedRoute>
        }
      />
      <Route
        path="user/*"
        element={
          <ProtectedRoute userRole="user">
            <UserRoute />
          </ProtectedRoute>
        }
      />
    </Routes>
  </Router>
);

export default AppRoute;
