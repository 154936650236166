import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  username: '',
  password: '',
};

export const loginSlice = createSlice({
  name: 'loginPage',
  initialState,
  reducers: {
    setUserName: (state, action) => {
      state.username = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    resetInfo: () => initialState,
  },
});

export const { setUserName, setPassword, resetInfo } = loginSlice.actions;

export default loginSlice.reducer;
