import { ThemeProvider } from '@material-ui/core';
import React from 'react';
import { ToastContainer } from 'react-toastify';

import { SocketNotification } from './components/Shared/SocketNotification';
import AppRoute from './routes';
import './assets/scss/index.scss';
import theme from './theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AppRoute />
      <SocketNotification />
      <ToastContainer autoClose={3000} pauseOnFocusLoss={false} newestOnTop />
    </ThemeProvider>
  );
}

export default App;
