import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpenSideMenu: true,
};

export const uiControlSlice = createSlice({
  name: 'uiControl',
  initialState,
  reducers: {
    setIsOpenSideMenu: (state) => {
      state.isOpenSideMenu = !state.isOpenSideMenu;
    },
  },
});

export const { setIsOpenSideMenu } = uiControlSlice.actions;

export default uiControlSlice.reducer;
