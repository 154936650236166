import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const initialState = {
  listOrg: [],
  formMode: 'edit',
  projectType: '',
  isLoading: false,
  generalFormProject: {
    id: '',
    approvalDecisionCode: '',
    projectCode: '',
    projectContract: {
      title: '',
      fileId: '',
      fileState: '',
      fileUrl: '',
    },
    state: 'PROPOSE',
    vieName: '',
    engName: '',
    period: 0,
    startTime: moment().format('YYYY-MM-DD'),
    endTime: null,
    extendTime: 0,
    budget: '',
    fundingUnit: '',
    hostUnit: 'Trường Đại học Công nghệ, ĐHQGHN',
    description: '',
    note: '',
    fileContent: '',
    fileProjectContractContent: '',
    projectExplanationFile: {
      title: '',
      fileId: '',
      fileState: '',
      fileUrl: '',
    },
  },
  projectMemberList: [],
  projectResult: new Array(2).fill().map((_, i) => ({
    id: i + 1,
    resultType: 0,
    resultName: '',
    quantity: 0,
    resultState: 'NOT_FINISHED',
  })),
  projectFiles: {
    projectFile: {
      files: [],
      certificationFiles: [],
      fileList: [],
    },
    projectResult: {
      files: [],
      certificationFiles: [],
      fileList: [],
    },
    projectExtend: {
      files: [],
      certificationFiles: [],
      fileList: [],
    },
    projectLiquidation: {
      files: [],
      certificationFiles: [],
      fileList: [],
    },
  },
  generalFormProjectState: {
    isDisableApprovalDecisionCode: true,
    isDisableProjectCode: true,
    isDisableProjectContract: true,
    isDisableState: true,
    isDisableVieName: false,
    isDisableEngName: false,
    isDisablePeriod: false,
    isDisableStartTime: false,
    isDisableEndTime: true,
    isDisableExtendTime: true,
    isDisableBudget: false,
    isDisableFundingUnit: false,
    isDisableHostUnit: false,
    isDisableDescription: false,
    isDisableFile: false,
    isDisableNote: true,
  },
  projectMemberState: {
    isDisableProjectMember: false,
  },
  projectResultState: {
    isDisableProjectResult: false,
    isUpdateResultState: false,
    isShowingResultState: false,
  },
  projectFileState: {
    isViewProjectFile: false,
    isDisableProjectFileList: false,
    isDisableProjectResultFile: false,
    isDisableProjectExtendFile: false,
    isDisableProjectLiquidateFile: false,
  },
  projectNoteState: {
    isDisableProjectNote: false,
  },
};

export const projectFormSlice = createSlice({
  name: 'projectForm',
  initialState,
  reducers: {
    setListOrg: (state, action) => {
      state.listOrg = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setFormMode: (state, action) => {
      state.formMode = action.payload;
    },
    setGeneralFormProjectInfo: (state, action) => {
      state.generalFormProject[action.payload.name] = action.payload.value;
    },
    setAllGeneralFormProjectInfo: (state, action) => {
      state.generalFormProject = action.payload;
    },
    setProjectMemberList: (state, action) => {
      state.projectMemberList = action.payload;
    },
    setProjectResult: (state, action) => {
      state.projectResult = action.payload;
    },
    setProjectType: (state, action) => {
      state.projectType = action.payload;
    },
    setProjectFile: (state, action) => {
      state.projectFiles = action.payload;
    },
    setGeneralFormProjectState: (state, action) => {
      state.generalFormProjectState = action.payload;
    },
    setProjectMemberState: (state, action) => {
      state.projectMemberState = action.payload;
    },
    setProjectResultState: (state, action) => {
      state.projectResultState = action.payload;
    },
    setProjectFileState: (state, action) => {
      state.projectFileState = action.payload;
    },
    setProjectNoteState: (state, action) => {
      state.projectNoteState = action.payload;
    },
    setDisableAllForm: (state) => {
      state.generalFormProjectState = {
        isDisableApprovalDecisionCode: true,
        isDisableProjectCode: true,
        isDisableState: true,
        isDisableVieName: true,
        isDisableEngName: true,
        isDisablePeriod: true,
        isDisableStartTime: true,
        isDisableEndTime: true,
        isDisableExtendTime: true,
        isDisableBudget: true,
        isDisableFundingUnit: true,
        isDisableHostUnit: true,
        isDisableDescription: true,
        isDisableFile: true,
        isDisableNote: true,
      };
      state.projectMemberState = {
        isDisableProjectMember: true,
      };
      state.projectResultState = {
        isDisableProjectResult: true,
        isUpdateResultState: true,
        isShowingResultState: true,
      };
      state.projectFileState = {
        isViewProjectFile: true,
        isDisableProjectFileList: true,
        isDisableProjectResultFile: true,
        isDisableProjectExtendFile: true,
        isDisableProjectLiquidateFile: true,
      };
      state.projectNoteState = {
        isDisableProjectNote: true,
      };
    },
    resetGeneral: (state) => {
      state.generalFormProject = initialState.generalFormProject;
    },
    resetProjectMemberList: (state) => {
      state.projectMemberList = initialState.projectMemberList;
    },
    resetProjectResult: (state) => {
      state.projectResult = initialState.projectResult;
    },
    resetProjectType: (state) => {
      state.projectType = initialState.projectType;
    },
    resetProjectFile: (state) => {
      state.projectFiles = initialState.projectFiles;
    },
    resetProjectFileUpload: (state) => {
      state.projectFiles.projectFile.fileList = [];
      state.projectFiles.projectResult.fileList = [];
      state.projectFiles.projectExtend.fileList = [];
      state.projectFiles.projectLiquidation.fileList = [];
    },
    resetFormState: (state) => {
      state.generalFormProjectState = initialState.generalFormProjectState;
      state.projectMemberState = initialState.projectMemberState;
      state.projectResultState = initialState.projectResultState;
      state.projectFileState = initialState.projectFileState;
      state.projectNoteState = initialState.projectNoteState;
    },
  },
});

export const {
  setListOrg,
  setIsLoading,
  setFormMode,
  setGeneralFormProjectInfo,
  setAllGeneralFormProjectInfo,
  setGeneralFormProjectState,
  setProjectMemberList,
  setProjectResult,
  setProjectType,
  setProjectFile,
  setProjectMemberState,
  setProjectResultState,
  setProjectFileState,
  setProjectNoteState,
  setDisableAllForm,
  resetGeneral,
  resetProjectMemberList,
  resetProjectResult,
  resetProjectType,
  resetProjectFile,
  resetProjectFileUpload,
  resetFormState,
} = projectFormSlice.actions;

export default projectFormSlice.reducer;
