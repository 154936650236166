import loadable from '@loadable/component';
import { Route, Routes } from 'react-router-dom';

import BackdropLoading from '../components/Shared/BackdropLoading/backdropLoading.component';

import { ProtectedRoute } from './protectedRoute';

const UserMainPage = loadable(() => import('../pages/User/index'), {
  fallback: <BackdropLoading />,
});

const AccountPage = loadable(() => import('../pages/User/userAccountDetail'), {
  fallback: <BackdropLoading />,
});

const NewProjectPage = loadable(() => import('../pages/User/newProject'), {
  fallback: <BackdropLoading />,
});

const NewCNProject = loadable(() => import('../pages/User/newProjectDetail'), {
  fallback: <BackdropLoading />,
});

const UserListProject = loadable(() => import('../pages/User/userProject'), {
  fallback: <BackdropLoading />,
});

const UserNotification = loadable(() => import('../pages/User/userNotification'), {
  fallback: <BackdropLoading />,
});

const CNProject = loadable(() => import('../pages/User/userProjectDetail'), {
  fallback: <BackdropLoading />,
});

const UserGuide = loadable(() => import('../pages/User/userGuide'), {
  fallback: <BackdropLoading />,
});

const UserRoute = () => (
  <Routes>
    <Route
      index
      element={
        <ProtectedRoute userRole="user">
          <UserMainPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="account"
      element={
        <ProtectedRoute userRole="user">
          <AccountPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="new-project/type"
      element={
        <ProtectedRoute userRole="user">
          <NewProjectPage />
        </ProtectedRoute>
      }
    />
    <Route
      path="new-project"
      element={
        <ProtectedRoute userRole="user">
          <NewCNProject />
        </ProtectedRoute>
      }
    />
    <Route
      path="project"
      element={
        <ProtectedRoute userRole="user">
          <UserListProject />
        </ProtectedRoute>
      }
    />
    <Route
      path="project/:id"
      element={
        <ProtectedRoute userRole="user">
          <CNProject />
        </ProtectedRoute>
      }
    />
    <Route
      path="notifications"
      element={
        <ProtectedRoute userRole="user">
          <UserNotification />
        </ProtectedRoute>
      }
    />
    <Route
      path="user-guide"
      element={
        <ProtectedRoute userRole="user">
          <UserGuide />
        </ProtectedRoute>
      }
    />
  </Routes>
);

export default UserRoute;
